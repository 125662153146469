import * as React from 'react'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined'
import FactoryOutlinedIcon from '@mui/icons-material/FactoryOutlined'
import AppSettingsAltOutlinedIcon from '@mui/icons-material/AppSettingsAltOutlined'

import { Fab, Switch, TextField, Typography } from '@mui/material'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/fr'
import './ApplicationDetailContainer.scss'
import dayjs from 'dayjs'
import { AppDetailType } from '../../../Services/Types/AppsDataTypes'

type AppleDetailContainerProps = {
  appDetail: AppDetailType | undefined
  editData: {
    setSubscriptionDate: React.Dispatch<React.SetStateAction<string | undefined>>
    setIdentifiant: React.Dispatch<React.SetStateAction<string | undefined>>
    setIsHide: React.Dispatch<React.SetStateAction<boolean>>
    setCertificates: React.Dispatch<React.SetStateAction<number | undefined>>
  }
  handleClickUpdate: () => void
}

export function AppleDetailContainer({ appDetail, editData, handleClickUpdate }: AppleDetailContainerProps) {
  const [isEdit, setIsEdit] = React.useState<boolean>(false)
  const [isHide, setIsHide] = React.useState<boolean>(appDetail?.ishide || false)
  const [certificates, setCertificates] = React.useState<number | undefined>(appDetail?.certificates || 0)
  dayjs.locale('fr')

  React.useEffect(() => {
    if (appDetail) {
      setIsHide(appDetail?.ishide || false)
      setCertificates(appDetail?.certificates || 0)
    }
  }, [appDetail])

  if (appDetail) {
    return (
      <div className="ApplicationDetailContainer">
        <div className="hide-switch-container">
          <span className="hide-switch-label">Masquer l'application?</span>
          <Switch
            checked={isHide}
            onChange={event => {
              if (isEdit) {
                setIsHide(event.target.checked)
                editData.setIsHide(event.target.checked)
              }
            }}
            color="primary"
            className="hide-switch"
            disabled={!isEdit}
          />
          <span className="hide-switch-label">{isHide ? 'Oui' : 'Non'}</span>
        </div>

        <TextField
          variant="standard"
          defaultValue={appDetail?.identifiant}
          label="Identifiant"
          InputProps={{
            readOnly: !isEdit,
          }}
          onChange={event => {
            editData.setIdentifiant(event.target.value)
          }}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
          <DatePicker
            label="Date abonnement"
            defaultValue={dayjs(appDetail?.subscription_expiration_date)}
            slotProps={{
              textField: {
                variant: 'standard',
              },
            }}
            disableOpenPicker={!isEdit}
            onChange={value => {
              editData.setSubscriptionDate(value?.format('YYYY-MM-DD') || undefined)
            }}
          />
        </LocalizationProvider>

        <div className="section-wrap">
          <section>
            <Typography variant="h6">
              <HelpOutlineOutlinedIcon /> Statut de l'application
            </Typography>
            <div>
              <TextField
                variant="standard"
                defaultValue={appDetail?.status}
                label="Statut de l'app"
                InputProps={{
                  readOnly: true,
                }}
                disabled={isEdit}
              />
              <TextField
                variant="standard"
                label="Etat déploiement"
                value={appDetail.etat}
                InputProps={{
                  readOnly: true,
                }}
                disabled={isEdit}
              />
            </div>
          </section>
          <section>
            <Typography variant="h6">
              <ScienceOutlinedIcon /> Version de test
            </Typography>
            <div>
              <TextField
                variant="standard"
                defaultValue={appDetail?.pre_release_version}
                label="Version test"
                InputProps={{
                  readOnly: true,
                }}
                disabled={isEdit}
              />
              <TextField
                variant="standard"
                defaultValue={appDetail?.pre_release_version_build}
                label="Build"
                InputProps={{
                  readOnly: true,
                }}
                disabled={isEdit}
              />
            </div>
          </section>
          <section>
            <Typography variant="h6">
              <FactoryOutlinedIcon /> Version de production
            </Typography>
            <div>
              <TextField
                variant="standard"
                defaultValue={appDetail?.prod_version}
                label="Version prod"
                InputProps={{
                  readOnly: true,
                }}
                disabled={isEdit}
              />
              <TextField
                variant="standard"
                defaultValue={appDetail?.prod_version_build}
                label="Build"
                InputProps={{
                  readOnly: true,
                }}
                disabled={isEdit}
              />
            </div>
          </section>
          <section>
            <Typography variant="h6">
              <AppSettingsAltOutlinedIcon /> Configuration & fichiers
            </Typography>
            <div>
              <TextField
                variant="standard"
                defaultValue={appDetail?.cgu_url ? 'OK' : 'KO'}
                label="Etat des CGU"
                InputProps={{
                  readOnly: true,
                }}
                disabled={isEdit}
              />
              <TextField
                variant="standard"
                defaultValue={appDetail?.cgu_url}
                label="Url des CGU"
                InputProps={{
                  readOnly: true,
                }}
                disabled={isEdit}
              />
              <div className="signinButton">
                <TextField
                  variant="standard"
                  value={certificates && certificates > 0 ? 'OK' : 'KO'}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Certificates"
                  InputProps={{
                    readOnly: !isEdit,
                  }}
                />
                {isEdit && (
                  <Switch
                    checked={!!(certificates && certificates > 0)}
                    onChange={event => {
                      setCertificates(event.target.checked ? 1 : 0)
                      editData.setCertificates(event.target.checked ? 1 : 0)
                    }}
                  />
                )}
              </div>
              <TextField
                variant="standard"
                defaultValue={appDetail?.ios_p8_is_defined ? 'OK' : 'KO'}
                label="Fichier P8"
                InputProps={{
                  readOnly: true,
                }}
                disabled={isEdit}
              />
            </div>
          </section>
        </div>
        {!isEdit ? (
          <Fab
            color="primary"
            aria-label="edit"
            variant="extended"
            className="editButton"
            sx={{
              position: 'fixed',
              bottom: '20px',
              right: '20px',
              gap: '0.5em',
            }}
            onClick={() => {
              setIsEdit(!isEdit)
            }}
          >
            <EditIcon />
            Modifier
          </Fab>
        ) : (
          <Fab
            color="success"
            aria-label="edit"
            variant="extended"
            className="editButton"
            sx={{
              position: 'fixed',
              bottom: '20px',
              right: '20px',
              gap: '0.5em',
            }}
            onClick={() => {
              setIsEdit(!isEdit)
              handleClickUpdate()
            }}
          >
            <SaveIcon />
            Valider
          </Fab>
        )}
      </div>
    )
  }
  return <div>Chargement ...</div>
}

export default AppleDetailContainer
