import { Button, TextField } from '@mui/material'
import React from 'react'
import { FormData } from '../../../Services/Types/ApplicationEditFormTypes'
import { getApiUrl } from '../../../Api/api'

function NewClient({
  formData,
  setState,
  headers,
  handleInputChange,
  refetch,
}: {
  formData: FormData
  setState: (state: 'SelectClient' | 'EditClient' | 'newClient') => void
  headers: { [key: string]: string }
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  refetch: () => void
}) {
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    const { identifier, name, p8File, iosKeyId, iosIssuerId, clientId, clientName } = formData
    event.preventDefault()

    await fetch(`${getApiUrl()}/client/new`, {
      method: 'POST',
      headers: {
        ...headers,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        identifier,
        name,
        p8File,
        iosKeyId,
        iosIssuerId,
        clientId,
        clientName,
      }),
    })
    refetch()
    setState('SelectClient')
  }

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <TextField label="Nom du client" variant="standard" fullWidth name="clientName" value={formData.clientName} onChange={handleInputChange} />
        <TextField label="fichier p8" variant="standard" fullWidth name="p8File" multiline maxRows={8} id="outlined-multiline-flexible" onChange={handleInputChange} />
        <TextField label="ios key id" variant="standard" fullWidth name="iosKeyId" value={formData.iosKeyId} onChange={handleInputChange} />
        <TextField label="ios issuerid" variant="standard" fullWidth name="iosIssuerId" value={formData.iosIssuerId} onChange={handleInputChange} />
      </div>
      <div className="button-group">
        <Button variant="outlined" color="primary" onClick={() => setState('SelectClient')}>
          retour
        </Button>
        <Button variant="contained" color="success" type="submit">
          Ajouter
        </Button>
      </div>
    </form>
  )
}

export default NewClient
