import * as React from 'react'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined'
import FactoryOutlinedIcon from '@mui/icons-material/FactoryOutlined'
import AppSettingsAltOutlinedIcon from '@mui/icons-material/AppSettingsAltOutlined'

import './ApplicationDetailContainer.scss'
import { Fab, Switch, TextField, Typography } from '@mui/material'
import { AppDetailType } from '../../../Services/Types/AppsDataTypes'

type AppleDetailContainerProps = {
  appDetail: AppDetailType | undefined
  editData: {
    setGouvCertificate: React.Dispatch<React.SetStateAction<string | undefined>>
    setPreReleaseVersion: React.Dispatch<React.SetStateAction<string | undefined>>
    setPreReleaseBuild: React.Dispatch<React.SetStateAction<string | undefined>>
    setProdBuild: React.Dispatch<React.SetStateAction<string | undefined>>
    setGoogleSignIn: React.Dispatch<React.SetStateAction<string | undefined>>
    setIdentifiant: React.Dispatch<React.SetStateAction<string | undefined>>
    setIsHide: React.Dispatch<React.SetStateAction<boolean>>
  }
  handleClickUpdate: () => void
}

export function GoogleDetailContainer({ appDetail, editData, handleClickUpdate }: AppleDetailContainerProps) {
  const [isEdit, setIsEdit] = React.useState<boolean>(false)
  const [googleSignIn, setGoogleSignIn] = React.useState<string | undefined>(appDetail?.google_sign_in || '')
  const [gouvCertificate, setGouvCertificate] = React.useState<string | undefined>(appDetail?.gouvernemental_data_certification || '')
  const [isHide, setIsHide] = React.useState<boolean>(appDetail?.ishide || false)

  if (appDetail) {
    return (
      <div className="ApplicationDetailContainer">
        <div className="hide-switch-container">
          <span className="hide-switch-label">Masquer l'application?</span>
          <Switch
            checked={isHide}
            onChange={event => {
              if (isEdit) {
                setIsHide(event.target.checked)
                editData.setIsHide(event.target.checked)
              }
            }}
            color="primary"
            className="hide-switch"
            disabled={!isEdit}
          />
          <span className="hide-switch-label">{isHide ? 'Oui' : 'Non'}</span>
        </div>
        <TextField
          variant="standard"
          defaultValue={appDetail?.identifiant}
          label="Identifiant"
          InputProps={{
            readOnly: !isEdit,
          }}
          onChange={event => {
            editData.setIdentifiant(event.target.value)
          }}
        />

        <div className="colContainer">
          <section>
            <Typography variant="h6">
              <HelpOutlineOutlinedIcon /> Statut de l'application
            </Typography>
            <div>
              <TextField
                variant="standard"
                defaultValue={appDetail?.status}
                label="Statut de l'app"
                InputProps={{
                  readOnly: true,
                }}
                disabled={isEdit}
              />
              <TextField
                variant="standard"
                label="Etat déploiement"
                value={appDetail?.etat}
                InputProps={{
                  readOnly: true,
                }}
                disabled={isEdit}
              />
            </div>
          </section>
          <section>
            <Typography variant="h6">
              <ScienceOutlinedIcon /> Version de test
            </Typography>
            <div>
              <TextField
                variant="standard"
                defaultValue={appDetail?.pre_release_version}
                label="Version test"
                InputProps={{
                  readOnly: !isEdit,
                }}
                onChange={event => {
                  editData.setPreReleaseVersion(event.target.value)
                }}
              />
              <TextField
                variant="standard"
                defaultValue={appDetail?.pre_release_version_build}
                label="Build"
                InputProps={{
                  readOnly: !isEdit,
                }}
                onChange={event => {
                  editData.setPreReleaseBuild(event.target.value)
                }}
              />
            </div>
          </section>
          <section>
            <Typography variant="h6">
              <FactoryOutlinedIcon /> Version de production
            </Typography>
            <div>
              <TextField
                variant="standard"
                defaultValue={appDetail?.prod_version}
                label="Version prod"
                InputProps={{
                  readOnly: true,
                }}
                disabled={isEdit}
              />
              <TextField
                variant="standard"
                defaultValue={appDetail?.prod_version_build}
                label="Build"
                InputProps={{
                  readOnly: !isEdit,
                }}
                onChange={event => {
                  editData.setProdBuild(event.target.value)
                }}
              />
            </div>
          </section>
          <section>
            <Typography variant="h6">
              <AppSettingsAltOutlinedIcon /> Configuration & fichiers
            </Typography>
            <div>
              <TextField
                variant="standard"
                defaultValue={appDetail?.cgu_url ? 'OK' : 'KO'}
                label="Etat des CGU"
                InputProps={{
                  readOnly: true,
                }}
                disabled={isEdit}
              />

              <TextField
                variant="standard"
                defaultValue={appDetail?.cgu_url}
                label="Url des CGU"
                InputProps={{
                  readOnly: true,
                }}
                disabled={isEdit}
              />
              <div className="signinButton">
                <TextField
                  variant="standard"
                  value={googleSignIn}
                  InputLabelProps={{
                    shrink: !!googleSignIn,
                  }}
                  label="Google Sign-In"
                  InputProps={{
                    readOnly: !isEdit,
                  }}
                />
                {isEdit && (
                  <Switch
                    checked={!!(googleSignIn && googleSignIn === 'Ok')}
                    onChange={event => {
                      setGoogleSignIn(event.target.checked ? 'Ok' : 'Ko')
                      editData.setGoogleSignIn(event.target.checked ? 'Ok' : 'Ko')
                    }}
                  />
                )}
              </div>
              <div className="signinButton">
                <TextField
                  variant="standard"
                  value={gouvCertificate}
                  InputLabelProps={{
                    shrink: !!gouvCertificate,
                  }}
                  label="Certification données gouvernementales"
                  InputProps={{
                    readOnly: true,
                  }}
                />
                {isEdit && (
                  <Switch
                    checked={!!(gouvCertificate && gouvCertificate === 'Ok')}
                    onChange={event => {
                      setGouvCertificate(event.target.checked ? 'Ok' : 'Ko')
                      editData.setGouvCertificate(event.target.checked ? 'Ok' : 'Ko')
                    }}
                  />
                )}
              </div>
            </div>
          </section>
        </div>
        {!isEdit ? (
          <Fab
            color="primary"
            aria-label="edit"
            variant="extended"
            className="editButton"
            sx={{
              position: 'fixed',
              bottom: '20px',
              right: '20px',
              gap: '0.5em',
            }}
            onClick={() => {
              setIsEdit(!isEdit)
            }}
          >
            <EditIcon />
            Modifier
          </Fab>
        ) : (
          <Fab
            color="success"
            aria-label="edit"
            variant="extended"
            className="editButton"
            sx={{
              position: 'fixed',
              bottom: '20px',
              right: '20px',
              gap: '0.5em',
            }}
            onClick={() => {
              setIsEdit(!isEdit)
              handleClickUpdate()
            }}
          >
            <SaveIcon />
            Valider
          </Fab>
        )}
      </div>
    )
  }
  return <div>Chargement ...</div>
}

export default GoogleDetailContainer
